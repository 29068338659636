import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/home";
import Programs from './pages/programs';
import ProgramsLoader from './pages/programs/programs-loader';
import FairPlay from './pages/fairplay';
import Instructors from './pages/instructors';
import InstructorsLoader from './pages/instructors/instructors-loader';
import TimeTable from './pages/time-table';
import ContactUs from './pages/contact-us';
import Layout from './ui/layout';
import './reset.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/programs',
        element: <Programs />,
      },
      {
        path: "/programs/:id",
        element: <ProgramsLoader />,
      },
      {
        path: '/instructors',
        element: <Instructors />,
      },
      {
        path: '/instructors/:id',
        element: <InstructorsLoader />,
      },
      {
        path: '/time-table',
        element: <TimeTable />,
      },
      {
        path: '/contact-us',
        element: <ContactUs />,
      },
      {
        path: '/fairplay',
        element: <FairPlay />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
