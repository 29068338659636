import { useEffect, useState } from 'react';
import NavigationPrimary from './navigation-primary';
import NavigationSecondary from './navigation-secondary';
import styled from 'styled-components';

const StyledNav = styled.nav`
  display: flex;
  width: 2rem;
  height: 100%;
  
  @media screen and (min-width: 1024px) {
    width: auto;
    padding-right: 1rem;
  }
`;

const MenuButton = styled.button`
  position: relative;
  top: 0.875rem;
  right: 8px;
  width: 1.5rem;

  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: #c12227;
    border-radius: 9px;
    opacity: 1;
    transition: all 0.3s ease;

    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 8px;
    }
    &:nth-child(3) {
      top: 16px;
    }
  }

  &.open span {
    &:nth-child(1) {
      top: 8px;
      transform: rotate(135deg);
    }
    &:nth-child(2) {
      opacity: 0;
      top: 60px;
    }
    &:nth-child(3) {
      top: 8px;
      transform: rotate(-135deg);
    }
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const NavContainer = styled.ul`
  position: absolute;
  right: 0;
  top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: #0f0;
  border-top: 1px solid var(--nav-color-border);

  @media screen and (min-width: 1024px) {
    position: relative;
    right: auto;
    top: auto;
    flex-direction: row; 
    width: auto;
    height: 100%;
    border-top: 0;
  }
`;

const SubContainer = styled.ul`
  position: relative;
  flex-direction: column;
  padding-left: 0.75rem;
  width: 100%;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  border-top: 1px solid var(--nav-color-border);
  background-color: var(--color-stone-200);
  box-shadow: 2px 2px 9px var(--color-stone-950-20);
  
  @media screen and (min-width: 1024px) {
    position: absolute;
    top: 3rem;
    width: ${(props) => `${props.small === "small" ? '10rem' : '13rem'}`};
    padding-left: 0;
    background-color: var(--color-stone-50);
  }
`;

const Navigation = () => {
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    const onResize = () => {
      setShowNav((show) => window.innerWidth >= 1024 ? true : false);
    }

    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.addEventListener('resize', onResize);
    }
  }, []);

  return (
    <StyledNav aria-label="Main">
      <MenuButton
        className={showNav ? 'open' : ''}
        onClick={() => { setShowNav((show) => !show); }}
      >
        <span /><span /><span />
      </MenuButton>

      {showNav && (
        <NavContainer>
          <NavigationPrimary to="/" text="Home" />
          <NavigationPrimary to="/programs" text="Programs" className="has-submenu">
            <SubContainer size="small">
              <NavigationSecondary to="/programs/kids" text="Kids" />
              <NavigationSecondary to="/programs/taekwondo" text="Taekwondo" />
              <NavigationSecondary to="/programs/hapkido" text="Hapkido" />
            </SubContainer>
          </NavigationPrimary>
          <NavigationPrimary to="/instructors" text="Instructors" className="has-submenu">
            <SubContainer size="normal">
              <NavigationSecondary to="/instructors/sung-soo-lee" text="Grand Master Sung Soo Lee" />
              <NavigationSecondary to="/instructors/cindy-rohan" text="Kwnajang Cindy Rohan" />
              <NavigationSecondary to="/instructors/brad-reid" text="Sabom Brad Reid" />
              <NavigationSecondary to="/instructors/macarla-debel" text="Sabom Macarla Debel" />
            </SubContainer>
          </NavigationPrimary>
          <NavigationPrimary to="/time-table" text="Time Table" />
          <NavigationPrimary to="/contact-us" text="Contact Us">
            <SubContainer>
              <NavigationSecondary to="/fairplay" text="Fairplay" />
            </SubContainer>
          </NavigationPrimary>
        </NavContainer>
      )}
    </StyledNav>
  );
};

export default Navigation;
