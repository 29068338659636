import { Outlet } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';

const Layout = () => (
  <div className="h-screen flex flex-col font-bodytext">
    <Header />

    <main className="flex-1 mt-[108px] md:overflow-y-auto">
      <Outlet />
      <Footer />
    </main>
  </div>
);

export default Layout;
