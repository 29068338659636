import React, { useState } from 'react';
import styled from 'styled-components';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Banner from '../components/banner';
import bgImage from '../assets/bhfma-building.jpg';
import Input from '../components/forms/input';
import Button from '../components/button';
import TextArea from '../components/forms/textarea';

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1240px;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 2rem;
  }
`;

const BGImage = styled.div`
  background-image: url(${bgImage});
  background-position: center;
  background-size: cover;
  height: 240px;
  
  @media screen and (min-width: 1024px) {
    height: 400px;
    border-radius: var(--border-radius-lg);
  }
`;

const Column1 = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: column;
  gap: 1rem;
  
  @media screen and (min-width: 1024px) {
    padding: 2rem 0 2rem 1rem;
  }
  `;

const Column2 = styled.div`
  flex: 1;
  padding: 2rem 1rem;
  `;

const P = styled.p`
  padding: 0 1rem;

  @media screen and (min-width: 1024px) {
    padding: 0 2rem;
  }
`;

const ContactUs = () => {
  const [state, setState] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const position = [-16.83455, 145.6949];

  const { name, email, subject, message } = state;

  const setStateProp = ({ key, value }) => {
    setState({
      ...state,
      [key]: value,
    })
  }

  const validateForm = (e) => {
    e.preventDefault();
    console.log('--submit--');
  }

  return (
    <>
      <Banner title="Contact Us" />

      <MapContainer center={position} zoom={18} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>

      <ContactDetails>
        <Column1>
          <BGImage />
          <P><strong>Address:</strong> Unit 2, 7-11 Salvado Drive, <span style={{ whiteSpace: 'nowrap' }}>Smithfield, QLD 4878</span></P>
          <P><strong>Phone:</strong> <a href="tel:07 4038 2633">07 4038 2633</a></P>
        </Column1>

        <Column2>
          <h2 style={{ fontSize: '1.5rem' }}>Contact form coming soon.</h2>
          {/* <form onSubmit={validateForm}>
            <Input
              fullWidth
              label="Full name"
              value={name}
              onChange={(e) => { setStateProp({ key: 'name', value: e.target.value }) }}
            />
            <Input
              fullWidth
              label="Email address"
              value={email}
              onChange={(e) => { setStateProp({ key: 'email', value: e.target.value }) }}
            />
            <Input
              fullWidth
              label="Subject"
              value={subject}
              onChange={(e) => { setStateProp({ key: 'subject', value: e.target.value }) }}
            />

            <TextArea
              fullWidth
              label="Your message"
              rows={5}
              value={message}
              onChange={(e) => { setStateProp({ key: 'message', value: e.target.value }) }}
            />

            <Button type="submit">
              Submit
            </Button>
          </form> */}
        </Column2>
      </ContactDetails>
    </>
  );
};

export default ContactUs;
