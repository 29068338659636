import React, { Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { stringToCamel } from '../../scripts/helper';

const Components = {
  sungSooLee: lazy(() => import('./sung-soo-lee')),
  cindyRohan: lazy(() => import('./cindy-rohan')),
  bradReid: lazy(() => import('./brad-reid')),
  macarlaDebel: lazy(() => import('./macarla-debel')),
};

const DynamicLoader = ({ title }) => {
  const Component = Components[stringToCamel(title)];

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component />
    </Suspense>
  );
};

const InstructorsLoader = () => {
  const { id } = useParams();

  return <DynamicLoader title={id} />;
};

export default InstructorsLoader;
