import React from 'react';
import Banner from '../components/banner';
import ModuleBeginner from '../modules/module-beginner';
import ModulePrograms from '../modules/module-programs';

const TimeTable = () => (
  <>
    <Banner title="Time Table" />

    <div className="px-6 py-8 time-table-mobile md:hidden">
      <h2 className="block mb-2 text-3xl uppercase text-red-800">
        Kinder Kubs (0-5)
      </h2>

      <p className="mb-4">
        <strong>Monday</strong>: 
        9:30 am - 10:00 am
      </p>

      <h2 className="block mt-6 mb-2 text-3xl uppercase text-red-800">
        Tiny Tigers (3-4)
      </h2>

      <p className="mb-4">
        <strong>Tuesday</strong>: 
        3:30 pm - 3:50 pm
      </p>

      <p className="mb-4">
        <strong>Thursday</strong>: 
        3:30 pm - 3:50 pm
      </p>

      <h2 className="block mt-6 mb-2 text-3xl uppercase text-red-800">
        Pandas (5-7)
      </h2>

      <p className="mb-4">
        <strong>Monday</strong>: 
        4:00 pm - 4:45pm
      </p>

      <p className="mb-4">
        <strong>Tuesday</strong>: 
        4:00 pm - 4:45pm
      </p>

      <p className="mb-4">
        <strong>Wednesday</strong>: 
        4:00 pm - 4:45pm
      </p>

      <p className="mb-4">
        <strong>Thursday</strong>: 
        4:00 pm - 4:45pm
      </p>

      <h2 className="block mt-6 mb-2 text-3xl uppercase text-red-800">
        Wolves (8-11)
      </h2>

      <p className="mb-4">
        <strong>Monday</strong>: 
        4:45pm - 5:30 pm
      </p>

      <p className="mb-4">
        <strong>Tuesday</strong>: 
        4:45pm - 5:30 pm
      </p>

      <p className="mb-4">
        <strong>Wednesday</strong>: 
        4:45pm - 5:30 pm
      </p>

      <p className="mb-4">
        <strong>Thursday</strong>: 
        4:45pm - 5:30 pm
      </p>

      <h2 className="block mt-6 mb-2 text-3xl uppercase text-red-800">
        Taekwondo (Adult)
      </h2>

      <p className="mb-4">
        <strong>Tuesday</strong>: 
        5:30 pm - 6:30 pm
      </p>

      <p className="mb-4">
        <strong>Wednesday</strong>: 
        5:30 pm - 6:30 pm
      </p>

      <p className="mb-4">
        <strong>Thursday</strong>: 
        5:30 pm - 6:30 pm
      </p>

      <h2 className="block mt-6 mb-2 text-3xl uppercase text-red-800">
        Hapkido (Adult)
      </h2>

      <p className="mb-4">
        <strong>Tuesday</strong>: 
        6:30 pm - 7:30 pm
      </p>

      <p>
        <strong>Thursday</strong>: 
        6:30 pm - 7:30 pm
      </p>
    </div>

    <div className="hidden container max-w-screen-lg px-6 pt-8 pb-12 md:block">
      <table className="w-full">
        <tbody>
          <tr>
            <th className="w-1/5 py-2 text-2xl uppercase">Time</th>
            <th className="w-1/5 py-2 text-2xl uppercase">Monday</th>
            <th className="w-1/5 py-2 text-2xl uppercase">Tuesday</th>
            <th className="w-1/5 py-2 text-2xl uppercase">Wednesday</th>
            <th className="w-1/5 py-2 text-2xl uppercase">Thursday</th>
          </tr>
          <tr>
            <td className="pt-8 pb-4 px-4 bg-time-table bg-cover text-center align-top font-bold text-stone-600 leading-5">9:30 am - <span className="text-nowrap">10:00 am</span></td>
            <td className="pt-8 pb-4 px-4 bg-time-table bg-cover text-center align-top font-bold text-stone-600 leading-5">Kinder Kubs <em className="block font-normal not-italic">( 0 - 5 YO )</em></td>
            <td className="pt-8 pb-4 px-4 bg-time-table bg-cover text-center align-top font-bold text-stone-600 leading-5"></td>
            <td className="pt-8 pb-4 px-4 bg-time-table bg-cover text-center align-top font-bold text-stone-600 leading-5"></td>
            <td className="pt-8 pb-4 px-4 bg-time-table bg-cover text-center align-top font-bold text-stone-600 leading-5"></td>
          </tr>
          <tr>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_20%] text-center align-top font-bold text-stone-600 leading-5">3:30 pm - <span className="text-nowrap">3:50 pm</span></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_20%] text-center align-top font-bold text-stone-600 leading-5"></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_20%] text-center align-top font-bold text-stone-600 leading-5">Tiny Tigers <em className="block font-normal not-italic">( 3 - 4 YO )</em></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_20%] text-center align-top font-bold text-stone-600 leading-5"></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_20%] text-center align-top font-bold text-stone-600 leading-5">Tiny Tigers <em className="block font-normal not-italic">( 3 - 4 YO )</em></td>
          </tr>
          <tr>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_40%] text-center align-top font-bold text-stone-600 leading-5">4:00 pm - <span className="text-nowrap">4:45 pm</span></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_40%] text-center align-top font-bold text-stone-600 leading-5">Pandas <em className="block font-normal not-italic">( 5 - 7 YO )</em></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_40%] text-center align-top font-bold text-stone-600 leading-5">Pandas <em className="block font-normal not-italic">( 5 - 7 YO )</em></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_40%] text-center align-top font-bold text-stone-600 leading-5">Pandas <em className="block font-normal not-italic">( 5 - 7 YO )</em></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_40%] text-center align-top font-bold text-stone-600 leading-5">Pandas <em className="block font-normal not-italic">( 5 - 7 YO )</em></td>
          </tr>
          <tr>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_60%] text-center align-top font-bold text-stone-600 leading-5">4:45 pm - <span className="text-nowrap">5:30 pm</span></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_60%] text-center align-top font-bold text-stone-600 leading-5">Wolves <em className="block font-normal not-italic">( 8 - 11 YO )</em></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_60%] text-center align-top font-bold text-stone-600 leading-5">Wolves <em className="block font-normal not-italic">( 8 - 11 YO )</em></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_60%] text-center align-top font-bold text-stone-600 leading-5">Wolves <em className="block font-normal not-italic">( 8 - 11 YO )</em></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_60%] text-center align-top font-bold text-stone-600 leading-5">Wolves <em className="block font-normal not-italic">( 8 - 11 YO )</em></td>
          </tr>
          <tr>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_80%] text-center align-top font-bold text-stone-600 leading-5">5:30 pm - <span className="text-nowrap">6:30 pm</span></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_80%] text-center align-top font-bold text-stone-600 leading-5"></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_80%] text-center align-top font-bold text-stone-600 leading-5">Taekwondo <em className="block font-normal not-italic">( Adult )</em></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_80%] text-center align-top font-bold text-stone-600 leading-5">Taekwondo <em className="block font-normal not-italic">( Adult )</em></td>
            <td className="py-4 px-4 bg-time-table bg-cover bg-[position:-0%_80%] text-center align-top font-bold text-stone-600 leading-5">Taekwondo <em className="block font-normal not-italic">( Adult )</em></td>
          </tr>
          <tr>
            <td className="pt-4 px-4 pb-8 bg-time-table bg-cover bg-bottom text-center align-top font-bold text-stone-600 leading-5">6:30 pm - <span className="text-nowrap">7:30 pm</span></td>
            <td className="pt-4 px-4 pb-8 bg-time-table bg-cover bg-bottom text-center align-top font-bold text-stone-600 leading-5"></td>
            <td className="pt-4 px-4 pb-8 bg-time-table bg-cover bg-bottom text-center align-top font-bold text-stone-600 leading-5">Hapkido <em className="block font-normal not-italic">( Adult )</em></td>
            <td className="pt-4 px-4 pb-8 bg-time-table bg-cover bg-bottom text-center align-top font-bold text-stone-600 leading-5"></td>
            <td className="pt-4 px-4 pb-8 bg-time-table bg-cover bg-bottom text-center align-top font-bold text-stone-600 leading-5">Hapkido <em className="block font-normal not-italic">( Adult )</em></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="hidden md:block">
      <ModuleBeginner />
      <ModulePrograms />
    </div>
  </>
);

export default TimeTable;
