import React, { Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';

const Components = {
  kids: lazy(() => import('./kids')),
  taekwondo: lazy(() => import('./taekwondo')),
  hapkido: lazy(() => import('./hapkido')),
};

const DynamicLoader = ({ title }) => {
  const Component = Components[title];

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component />
    </Suspense>
  );
};

const ProgramsLoader = () => {
  const { id } = useParams();

  return <DynamicLoader title={id} />;
};

export default ProgramsLoader;
