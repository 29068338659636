import { forwardRef } from "react";
import styled from "styled-components";

const StyledTextArea = styled.textarea.withConfig({
  shouldForwardProp: (prop) => [
    'placeholder', 'value', 'type', 'onBlur',
    'onChange', 'onFocus', 'rows'
  ].includes(prop)
})`
  display: block;
  position: relative;
  margin-top: 0.25rem;
  padding: 0.5rem 0.5rem;
  border: 2px solid var(--color-stone-400);
  background-color:  var(--color-stone-0);
  border-radius: var(--border-radius-sm);

  ${(props) => `${props.fullWidth ? 'width: 100%;' : ''}`}

  &:focus,
  &:hover {
    box-shadow: 0 0 0 4px ${(props) => `${props.pageColour !== null ? props.pageColour : 'var(--color-content-bg)'}`}, 0 0 0 7px var(--color-form-focus);
    outline: none;
  }

  ${(props) => props.classes}
`;

const Fieldset = styled.fieldset`
  margin-bottom: 0.75rem;
`;

const TextArea = forwardRef(({
  label,
  placeholder,
  value,
  classes,
  fullWidth = false,
  pageColour = null,
  onBlur,
  onChange,
  onFocus,
  rows,
}, ref) => (
  <Fieldset>
    {label && <label>{label}</label>}
    <StyledTextArea
      ref={ref}
      placeholder={placeholder}
      classes={classes}
      fullWidth={fullWidth}
      pageColour={pageColour}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      rows={rows}
    >
      {value}
    </StyledTextArea>
  </Fieldset>
));

export default TextArea;