import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledLi = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--color-stone-50);
  border-bottom: 1px solid var(--nav-color-border);

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    width: auto;
    height: 100%;
    border-bottom: 0;

    &:not(:last-of-type):after {
      content: '|';
      position: absolute;
      right: 0;
      top: 10px;
    }
  }

  & > ul {
    @media screen and (min-width: 1024px) {
      display: none; 
    }
  }
  &:hover > ul { display: flex; }
`;

const StyledNavigationLink = styled(NavLink)`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.25rem 1rem;
  border-bottom: 1px solid var(--color-stone-50);
  border-bottom-width: 4px;
  z-index: 20;
  
  @media screen and (min-width: 1024px) {
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    width: auto;
    justify-content: center;
  }

  &:hover {
    border-bottom-color: var(--color-primary);
  }

  &.active {
    color: var(--color-primary);
  }
`;

const NavigationPrimary = ({ children, text, to }) => (
  <StyledLi>
    <StyledNavigationLink to={to}>{text}</StyledNavigationLink>
    {children}
  </StyledLi>
);

export default NavigationPrimary;
