import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import OneFmaLogo from "./one-fma-logo";

const StyledLinkLogo = styled(Link)`
  ${(props) => props.type === 'header'
    ? css`
      position: absolute;
      left: 0.5rem;
      top: -3.25rem;

      @media screen and (min-width: 1024px) {
        left: 1rem;
        top: -3.5rem;
      }
    `
    : css`
      position: relative;

      @media screen and (min-width: 1024px) {
      }
    `
  }
`;

const LogoShadow = styled.div`
  position: absolute;
  left: 0.3125rem;
  top: 0.3125rem;
  width: calc(128px - 0.625rem);
  height: calc(128px - 0.625rem);
  border-radius: 50%;

  ${(props) => props.type === 'header'
    ? css`
  box-shadow: 0 10px 15px -3px var(--color-stone-800-40),
    0 4px 6px -4px var(--color-stone-800-40);
    `
    : css`
  box-shadow: 0 2px 15px -3px var(--color-stone-800-40),
    0 0 6px -4px var(--color-stone-800-40);
    `
  }

  @media screen and (min-width: 1024px) {
    width: calc(152px - 0.625rem);
    height: calc(152px - 0.625rem);
  }
`;

const LinkLogo = ({ to, type }) => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const onResize = () => {
      setIsDesktop((show) => window.innerWidth >= 1024 ? true : false);
    }
  
    window.addEventListener('resize', onResize);
    onResize();
  
    return () => {
      window.addEventListener('resize', onResize);
    }
  }, []);
  
  return (
    <StyledLinkLogo to="/" type={type}>
      <OneFmaLogo size={isDesktop ? 152 : 128} />
      <LogoShadow type={type} />
    </StyledLinkLogo>
  );
};

export default LinkLogo;
