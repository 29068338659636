export const programsExcerpt = [
  {
    title: 'Kids Martial Arts',
    description: 'Enhance every aspect of your child’s life focusing on life skills such as goal-setting, integrity and discipline.',
    buttonText: 'Read more',
    image: '/img/kids-01.jpg',
    url: '/programs/kids',
  },
  {
    title: 'Taekwondo',
    description: 'Teaching students to maintain good habits not only in physical activity and martial arts, but in life.',
    buttonText: 'Read more',
    image: 'img/adult-01.jpg',
    url: '/programs/taekwondo',
  },
  {
    title: 'Hapkido',
    description: 'Learn of your body\'s inner strength and how to use your attackers strength against them.',
    buttonText: 'Read more',
    image: 'img/hapkido.jpg',
    url: '/programs/hapkido',
  },
];

export const programsGroups = [
  {
    description: (<p>
      One Family Martial Arts&apos; Kids Martial Arts and Kinder Kicks classes are a fun activity
      that will enhance every aspect of your child&apos;s life. One Family Martial Arts&apos;
      dedicated instructors have designed an incredible Kids Martial Arts program that focuses on life
      skills such as goal-setting, integrity and discipline to make your child a great person inside and
      outside of the dojang.
    </p>),
    title: 'Kids Martial Arts',
    items: [
      {
        name: 'Kinder Kubs',
        age: '(0 - 5 yo)',
        url: '/programs/kids#kinder-kubs',
        image: '/img/bhfma-360.png',
      },
      {
        name: 'Tiny Tigers',
        age: '(3 - 4 yo)',
        url: '/programs/kids#tiny-tigers',
        image: '/img/bhfma-360.png',
      },
      {
        name: 'Pandas',
        age: '(5 - 7 yo)',
        url: '/programs/kids#pandas',
        image: '/img/bhfma-360.png',
      },
      {
        name: 'Wolves',
        age: '(8 - 11 yo)',
        url: '/programs/kids#wolves',
        image: '/img/bhfma-360.png',
      },
    ],
  },
  {
    description: (<p>
      At One Family Martial Arts our programs are designed to give you good habits and help you
      with your fitness goals with the benefit of effective self-defence.
    </p>),
    title: 'Adult Martial Arts',
    items: [
      {
        name: 'Taekwondo',
        url: '/programs/taekwondo',
        image: '/img/bhfma-360.png',
      },
      {
        name: 'Hapkido',
        url: '/programs/hapkido',
        image: '/img/bhfma-360.png',
      },
    ],
  },
];
