import React from 'react';
import Banner from '../components/banner';
import ModuleTrial from '../modules/module-trial';
import CardPrograms from '../components/card-programs';
import { programsGroups } from '../content/programs';

const Programs = () => (
  <>
    <Banner
      title="Programs"
      image=" bg-[url('../public/img/jang-bong-training.jpg')] bg-cover bg-center h-36 md:h-96"
    />

    {programsGroups.map((program) => <CardPrograms key={program.title} program={program} />)}

    <ModuleTrial />
  </>
);

export default Programs;
